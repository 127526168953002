.headerContainer {
    margin: 20px;
    font-weight: 600;
    color: White;
    font-size: 20px;
}

.containerSection {
    display: flex;
    justify-content: space-between;
    margin: 20px;
    padding: 5px;
}

.userNameBox {
    display: flex;
    align-items: baseline;
    justify-content: center;
}

.count {
    color: white;
}

.username {
    color: white;
    font-size: 18px;
    font-weight: 500;
    margin-left: 10%;
}

.unBlockBtn {
    border: 1px solid red !important;
    border-radius: 50px;
    background-color: red;
    color: white;
    width: 100px;
    height: 30px;
    font-weight: 600;
    cursor: pointer;
}